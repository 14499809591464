import React from 'react';
import '../assets/css/main.css'; 
import Img from "../assets/image/html.png";
import Img1 from "../assets/image/mangodb.png";
import Img2 from "../assets/image/angular.png";
import Img3 from "../assets/image/node.png";
import Img4 from "../assets/image/Cocos.png";
import Img5 from "../assets/image/css-3.png";
import Img6 from "../assets/image/bootstrap.png";
import Img7 from "../assets/image/flutter.png";
import Img8 from "../assets/image/java.png";
import Img9 from "../assets/image/react.png";
import Img10 from "../assets/image/aws.png";
import Img11 from "../assets/image/firebase.png";


const OurSpecialization = () => {
    return (
        <div className="specialization-container" id="techstack">
            <h4><strong>We specialized in...</strong></h4>
            <div className="specialization-grid">
                <img src={Img} alt="Specialization"  className='special'/>
                <img src={Img1} alt="Specialization" className='special'/>
                <img src={Img2} alt="Specialization" className='special'/>
                <img src={Img3} alt="Specialization" className='special'/>
                <img src={Img4} alt="Specialization" className='special'/>
                <img src={Img5} alt="Specialization" className='special'/>
                <img src={Img6} alt="Specialization" className='special'/>
                <img src={Img7} alt="Specialization" className='special'/>
                <img src={Img8} alt="Specialization" className='special' />
                <img src={Img9} alt="Specialization" className='special'/>
                <img src={Img10} alt="Specialization" className='special'/>
                <img src={Img11} alt="Specialization" className='special'/>
                </div></div>
                )};
                export default OurSpecialization;